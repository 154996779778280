import React, { useState } from "react";
import { addWeeks, startOfDay } from "date-fns";

import { MeetingList } from "./components/MeetingList";

import {
  Stack,
  Box,
  SelectChangeEvent,
  Divider,
  LinearProgress,
} from "@mui/material";
import { FilterTypes, Filters, defaultFilters } from "./components/Filters";
import { MeetingDetailsContainer } from "./components/meeting_details/MeetingDetails";
import { useBoundStore } from "../../store/store";
import { Meeting, isMissingData } from "../../model/meeting";

export const MeetingListView = () => {
  const meetings = useBoundStore((state) => state.meetings);
  const hostsById = useBoundStore((state) => state.hostsById);
  const loadMeetings = useBoundStore((state) => state.load);

  const [filteredMeetings, setFilteredMeetings] = useState<Meeting[]>([]);
  const [filters, setFilters] = useState<FilterTypes>(defaultFilters);
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting>(
    meetings[selectedIdx!]
  );

  function handleSelectMeeting(idx: number, mId: string) {
    setSelectedIdx(idx);
    setSelectedMeeting(meetings.find((m) => m.id === mId)!);
  }

  function handleUpdateFilter(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent,
    filter: keyof FilterTypes
  ) {
    setFilters({ ...filters, [filter]: e.target.value });
  }

  React.useEffect(() => {
    async function loadData() {
      const now = new Date();
      const start = startOfDay(now);
      const end = addWeeks(start, 4);
      await loadMeetings(start, end);
    }

    loadData();
  }, []);

  React.useEffect(() => {
    if (meetings.length > 0) {
      setSelectedIdx(0);
      setSelectedMeeting(meetings[selectedIdx!]);
    }
  }, [meetings]);

  React.useEffect(() => {
    if (filters !== defaultFilters) {
      let filteredMeetings = [...meetings];

      if (filters.search && filters.search !== "All") {
        filteredMeetings = filteredMeetings.filter((m) =>
          m.title?.toLowerCase().includes(filters.search!.toLowerCase())
        );
      }

      if (filters.meetingType && filters.meetingType !== "All") {
        filteredMeetings = filteredMeetings.filter(
          (m) => m.metadata.meetingType === filters.meetingType
        );
      }

      if (filters.status && filters.status !== "All") {
        if (filters.status === "NotVisible") {
          filteredMeetings = filteredMeetings.filter(
            (m) => !m.metadata.memberVisible
          );
        }
        if (filters.status === "Missing data") {
          filteredMeetings = filteredMeetings.filter((m) => isMissingData(m));
        }
      }

      if (filters.host && filters.host !== "All") {
        filteredMeetings = filteredMeetings.filter(
          (m) => m.metadata.host?.displayName === filters.host
        );
      }
      setFilteredMeetings(filteredMeetings);
    }
  }, [filters, meetings]);

  const activeFilters = filters !== defaultFilters;
  const noResultsMatchingFilters =
    filters !== defaultFilters && !meetings.length;

  const hostList = Object.entries(hostsById).map(([id, host]) => ({
    id,
    displayName: host.displayName,
  }));
  hostList.sort((a, b) => a.displayName.localeCompare(b.displayName));

  return (
    <Stack gap={1} sx={{ marginTop: "20px", width: "100%" }}>
      {!meetings.length ? (
        <LinearProgress />
      ) : (
        <>
          <Filters
            hosts={hostList.map((h) => h.displayName)}
            filters={filters}
            hasFilters={activeFilters}
            onClearFilters={() => setFilters(defaultFilters)}
            onMeetingTypeChange={(e: SelectChangeEvent) =>
              handleUpdateFilter(e, "meetingType")
            }
            onSearchChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleUpdateFilter(e, "search")
            }
            onStatusChange={(e: SelectChangeEvent) =>
              handleUpdateFilter(e, "status")
            }
            onHostChange={(e: SelectChangeEvent) =>
              handleUpdateFilter(e, "host")
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              bgcolor: "background.paper",
              height: "100%",
            }}
          >
            {noResultsMatchingFilters ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <h2>No meetings found</h2>
              </Box>
            ) : (
              <MeetingList
                meetings={activeFilters ? filteredMeetings : meetings}
                onSelectMeeting={handleSelectMeeting}
                selectedIdx={selectedIdx}
              />
            )}
            <Divider orientation="vertical" flexItem />
            {selectedMeeting && (
              <MeetingDetailsContainer selectedMeeting={selectedMeeting} />
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

export const CalendarManagement = () => {
  return <MeetingListView />;
};
