import React from 'react';
import { UserPrivate, UserPublic } from '@focusedspace/gql-api';
import { Logger } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AuthEventData, CognitoUserAmplify } from '@aws-amplify/ui';
import { Authenticator } from '@aws-amplify/ui-react';
import { FocusedUser, getFullUserFromDb } from "../api/users";

const logger = new Logger('focused.space');

export interface AuthenticatedUserState {
  isAuthenticated: boolean,
  isUserLoading: boolean,
  authenticatedUser: any,
  currentSession?: CognitoUserSession,
  focusedUser: FocusedUser,
  isHost: boolean,
  signOut: () => void
}

const fakeUserPublic: UserPublic = {
    __typename: 'UserPublic',
    id: 'fakeuser',
    displayName: 'fake',
    messagingArn: 'fake',
    createdAt: 'fake',
    updatedAt: 'fake',
}

const fakeUserPrivate: UserPrivate = {
    __typename: 'UserPrivate',
    id: 'fakeuser',
    userPublic: fakeUserPublic,
    email: 'fake',
    createdAt: 'fake',
    updatedAt: 'fake',
}

const fakeFocusedUser: FocusedUser = {
  public: fakeUserPublic,
  private: fakeUserPrivate
}

const initialUserState: AuthenticatedUserState = {
  isAuthenticated: false,
  isUserLoading: true,
  authenticatedUser: undefined,
  currentSession: undefined,
  focusedUser: fakeFocusedUser,
  isHost: false,
  signOut: () => {}
};

const AuthenticatedUserStateContext = React.createContext<AuthenticatedUserState>(initialUserState);

interface InternalProviderProps {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined, 
  user?: CognitoUserAmplify
}

const InternalProvider: React.FC<React.PropsWithChildren<InternalProviderProps>> = ({signOut, user, children}) => {
  const [focusedUser, setFocusedUser] = React.useState<FocusedUser>();
  const [userSession, setUserSession] = React.useState<CognitoUserSession>();
  
  const setUserState = async () => {
    if (user) {
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (session) {
          setUserSession(session);
        } else {
          throw new Error(`unable to retrieve user session, ${err}`)
        }
      });

      const dbUserResult = await getFullUserFromDb(user.getUsername());
      logger.debug(dbUserResult);
      if (dbUserResult) {
        setFocusedUser(dbUserResult);
      }
    }
  }

  React.useEffect(() => {
      if (!user) {
        console.info('user is undefined');
        return;
      }

      if (!signOut) {
        console.info('signOut is undefined');
        return;
      }

      setUserState();
    },
  [])

  const userState = React.useCallback(() => {
    if (!focusedUser || !user || !userSession) {
      return initialUserState;
    }

    const token = userSession.getAccessToken();
    if (token) {
      const userGroups = token.payload['cognito:groups'] as Array<string>;
      const foundHostGroup = userGroups && userGroups.includes('Hosts');

      return ({
        isAuthenticated: true,
        isUserLoading: false,
        authenticatedUser: user,
        currentSession: userSession,
        focusedUser,
        isHost: foundHostGroup,
        signOut: () => {
          if (signOut) {
            signOut();
          }
        }
      });
    } else {
      console.log('no token?')
      return initialUserState;
    }
  }, [focusedUser, userSession]);

  return <AuthenticatedUserStateContext.Provider value={userState()}>
    {children}
  </AuthenticatedUserStateContext.Provider>;
}

export const AuthenticatedUserProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <Authenticator>
      {({signOut, user}) => (
        <InternalProvider signOut={signOut} user={user}>
          {children}
        </InternalProvider>
      )}
    </Authenticator>
  );
}

export const useAuthenticatedUserState = () => {
  const context = React.useContext(AuthenticatedUserStateContext);

  if (!context) {
    throw new Error('useAuthenticatedUserState must be used within AuthenticatedUserStateProvider');
  }

  return context;
}