import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ResendActivation } from "./ResendActivation";
import { AsyncBoundary, useController, useSuspense } from "@rest-hooks/react";
import {
  UserAdminInfo,
  usersAdminInfoEndpoint,
  usersAdminInfoEndpointPaginated,
} from "../../api/users";
import { LinearProgress, Tooltip } from "@mui/material";
import { SendNotification } from "./SendNotification";

interface UserListItemProps {
  user: UserAdminInfo;
  index: number;
}

export const UserListItem: React.FC<UserListItemProps> = ({ user, index }) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "solid 1px gray",
      }}
    >
      <Box
        sx={{
          flex: "1",
        }}
      >
        <Typography>{index + 1}.</Typography>
      </Box>
      <Tooltip title={`User ID: ${user.id}`}>
        <Box
          sx={{
            flex: "2",
          }}
        >
          <Typography>{user.email}</Typography>
        </Box>
      </Tooltip>
      <Box
        sx={{
          flex: "2",
        }}
      >
        <Typography>{user.displayName}</Typography>
      </Box>
      <Box
        sx={{
          flex: "2",
        }}
      >
        <Typography
          sx={{
            color: user.accountState === "CONFIRMED" ? "green" : "red",
          }}
        >
          {user.accountState}
        </Typography>
      </Box>
      <Box>
        <ResendActivation userEmail={user.email} userId={user.id} />
      </Box>
      <Box
        sx={{
          flex: "2",
        }}
      >
        {!!user.pushIds.length && (
          <SendNotification userId={user.id} userName={user.displayName} />
        )}
      </Box>
    </Box>
  );
};

export const UserListPage = () => {
  const { users, nextToken } = useSuspense(usersAdminInfoEndpoint);
  const ctrl = useController();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (nextToken && nextToken.length > 0) {
      setLoading(true);
      ctrl.fetch(usersAdminInfoEndpointPaginated, {
        nextToken,
      });
    } else {
      setLoading(false);
    }
  }, [ctrl, nextToken]);

  users.sort((a, b) => {
    if (a.email < b.email) {
      return -1;
    } else if (a.email > b.email) {
      return 1;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <LinearProgress />
        </Box>
      )}
      {users.map((u, idx) => (
        <UserListItem key={u.id} index={idx} user={u} />
      ))}
    </Box>
  );
};

export const UserList = () => {
  return (
    <AsyncBoundary>
      <UserListPage />
    </AsyncBoundary>
  );
};
