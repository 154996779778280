import React from "react";

import {
  ApolloClient,
  ApolloError,
  gql,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  createFocusedUser,
  CreateFocusedUserMutation,
  CreateFocusedUserMutationVariables,
  getUserPrivate,
  GetUserPrivateQuery,
  GetUserPrivateQueryVariables,
  listUserPrivates,
  ListUserPrivatesQuery,
  ListUserPrivatesQueryVariables,
  resetPassword,
  ResetPasswordMutation,
  UserPrivate,
} from "@focusedspace/gql-api";

type ReturnStatusType = { loading: boolean; error: ApolloError | undefined };

export const useCreateFocusedUser = () => {
  const [createFocusedUserFn, { loading, error }] =
    useMutation<CreateFocusedUserMutation>(gql(createFocusedUser), {
      refetchQueries: ["ListUserPrivates", "ListUserPublics", "GetUserPrivate"],
    });

  const createFocusedUserClosure = (userEmail: string, displayName: string) => {
    const now = new Date();
    const variables: CreateFocusedUserMutationVariables = {
      userEmail,
      displayName,
    };

    return createFocusedUserFn({ variables });
  };

  type returnFunction = typeof createFocusedUserClosure;
  type returnTuple = [returnFunction, ReturnStatusType];
  const result: returnTuple = [createFocusedUserClosure, { loading, error }];

  return result;
};

export const useResetPassword = () => {
  const [createFocusedUserFn, { loading, error }] =
    useMutation<ResetPasswordMutation>(gql(resetPassword));

  const resetPasswordClosure = (userEmail: string) => {
    const variables: CreateFocusedUserMutationVariables = {
      userEmail,
    };

    return createFocusedUserFn({ variables });
  };

  type returnFunction = typeof resetPasswordClosure;
  type returnTuple = [returnFunction, ReturnStatusType];
  const result: returnTuple = [resetPasswordClosure, { loading, error }];

  return result;
};

export const useListUsers = (nextToken: string | null) => {
  const limit = 500;

  const variables: ListUserPrivatesQueryVariables = {
    limit: limit,
    nextToken,
  };

  return useQuery<ListUserPrivatesQuery>(gql(listUserPrivates), { variables });
};

export const useGetUserPrivate = (userId: string) => {
  const variables: GetUserPrivateQueryVariables = {
    id: userId,
  };

  return useQuery<GetUserPrivateQuery>(gql(getUserPrivate), { variables });
};

export const getUserPrivateById = async (
  client: ApolloClient<object>,
  userId: string
) => {
  const variables: GetUserPrivateQueryVariables = {
    id: userId,
  };
  const res = await client.query<GetUserPrivateQuery>({
    query: gql(getUserPrivate),
    variables: variables,
  });
  if (res.error) {
    throw res.error;
  }
  if (res.data) {
    const data = res.data;
    if (data.getUserPrivate) {
      return data.getUserPrivate as UserPrivate;
    }
  }
  throw new Error("No user found");
};

export const getUserPrivateByIds = async (
  client: ApolloClient<object>,
  userIds: string[]
) => {
  var nextToken: string | null | undefined = null;
  const goalDays: UserPrivate[] = [];

  const userIdPromises = userIds.map((userId) =>
    getUserPrivateById(client, userId)
  );
  const userIdsResolved = await Promise.all(userIdPromises);

  return new Map(userIdsResolved.map((user) => [user.id, user]));
};
