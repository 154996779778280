import React from "react";

import Box from "@mui/material/Box";
import { useApolloClient } from "@apollo/client";
import { GoalDay, UserPrivate } from "@focusedspace/gql-api";
import {
  deleteGoalDayAndGoals,
  getGoalDaysForDates,
} from "../../hooks/stores/GoalDays";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import CardActions from "@mui/material/CardActions";

export const Management = () => {
  const apolloClient = useApolloClient();
  const [goalDays, setGoalDays] = React.useState<GoalDay[]>();
  const [userPrivateData, setUserPrivateData] =
    React.useState<Map<string, UserPrivate>>();
  const [openDeleteId, setOpenDeleteId] = React.useState<string>();

  React.useEffect(() => {
    const fetchGoalDays = async () => {
      const goalDaysAndUsers = await getGoalDaysForDates(
        apolloClient,
        new Date()
      );
      setGoalDays(goalDaysAndUsers.goalDays);
      setUserPrivateData(goalDaysAndUsers.userPrivateData);
    };

    fetchGoalDays();
  }, []);

  const handleStartDeleteGoalDay = async (goalDayId: string) => {
    setOpenDeleteId(goalDayId);
    //const result = await deleteGoalDayAndGoals(apolloClient, goalDayId);
  };

  const handleCancelDelete = () => {
    setOpenDeleteId(undefined);
  };

  const handleConfirmDelete = async () => {
    if (openDeleteId) {
      const result = await deleteGoalDayAndGoals(apolloClient, openDeleteId);
      if (result) {
        setGoalDays(goalDays?.filter((gd) => gd.id !== openDeleteId));
      }
    }
    setOpenDeleteId(undefined);
  };

  const makeGoalDayCard = (goalDay: GoalDay, userPrivate: UserPrivate) => {
    return (
      <Card
        key={goalDay.id}
        style={{
          marginBottom: "10px",
        }}
      >
        <CardContent>
          <Typography variant="h5">
            {userPrivate.userPublic!.displayName} ({goalDay.id})
          </Typography>
          <Typography>User ID: {goalDay.userId}</Typography>
          <Typography>Day Start: {goalDay.dayStartUtc}</Typography>
          {(goalDay.startedAt || goalDay.sharedAt) && (
            <Typography
              sx={{
                color: "darkgreen",
              }}
            >
              ({goalDay.startedAt ? "Started" : ""}
              {goalDay.startedAt && goalDay.sharedAt ? " + " : ""}
              {goalDay.sharedAt ? "Shared" : ""})
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <IconButton onClick={() => handleStartDeleteGoalDay(goalDay.id)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  };

  return (
    <Box>
      {!goalDays && <Typography variant="h4">Loading...</Typography>}
      {goalDays && (
        <Box>
          <Box>
            <Typography variant="h4">
              Loaded {goalDays.length} goal days
            </Typography>
            <Typography variant="h5">
              {goalDays.filter((gd) => gd.startedAt).length} started,
              {goalDays.filter((gd) => gd.sharedAt).length} shared.
            </Typography>
          </Box>
          <Box>
            {goalDays.map((gd) =>
              makeGoalDayCard(gd, userPrivateData!.get(gd.userId)!)
            )}
          </Box>
        </Box>
      )}
      <Dialog
        open={openDeleteId !== undefined}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Goal Day?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting someone's goal day and all their goals for the day may be
            an extremely rude thing to do. Are you sure you want to do this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            I am 100% certain.
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
