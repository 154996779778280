import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  getUserPrivate,
  getUserPublic,
  GetUserPublicQuery,
  listUserPublics,
  ListUserPublicsQuery,
  updateUserPublic,
  UpdateUserPublicMutation,
  UserPrivate,
  UserPublic,
  GetUserPrivateQuery,
} from "@focusedspace/gql-api";
import { Entity, schema } from "@rest-hooks/rest";
import AuthdEndpoint from "./AuthdEndpoint";
import { BASE_URL } from "./constants";

export interface FocusedUser {
  public: UserPublic;
  private: UserPrivate;
}

export interface UserUpdateValues {
  id: string;
  displayName: string;
  location: string;
}

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export async function getFullUserFromDb(
  userId: string,
  retries: number = 3
): Promise<FocusedUser> {
  console.debug(`Loading pub/priv user record with ID ${userId}`);

  let userPublic: UserPublic | undefined;
  let userPrivate: UserPrivate | undefined;

  for (let x = 0; x < retries; x++) {
    const [respPublic, respPrivate] = await Promise.all([
      API.graphql(graphqlOperation(getUserPublic, { id: userId })),
      API.graphql(graphqlOperation(getUserPrivate, { id: userId })),
    ]);

    console.debug(
      `Received responses: pub ${JSON.stringify(
        respPublic
      )} priv ${JSON.stringify(respPrivate)}`
    );

    userPublic =
      ((respPublic as GraphQLResult<any>).data as GetUserPublicQuery)
        .getUserPublic ?? undefined;
    userPrivate =
      ((respPrivate as GraphQLResult<any>).data as GetUserPrivateQuery)
        .getUserPrivate ?? undefined;

    if (userPublic && userPrivate) {
      break;
    }

    await sleep(500 * (x + 1));
  }

  if (!userPublic || !userPrivate) {
    console.error(
      `Unable to retrieve full user record: pub ${userPublic} priv ${userPrivate}`
    );
    throw Error("Unable to load user record.");
  }

  return { public: userPublic, private: userPrivate };
}

export async function getUsersFromDb(userIds: string[]): Promise<UserPublic[]> {
  if (userIds.length === 0) {
    console.debug("getUsersFromDb called with empty list of user Ids");
    return [] as UserPublic[];
  }
  const filter = {
    or: userIds.map((userId) => ({ id: { eq: userId } })),
  };
  console.debug("getUsersFromDb using filter: ", filter);
  const users = (await API.graphql(
    graphqlOperation(listUserPublics, { limit: 50, filter: filter })
  )) as {
    data: ListUserPublicsQuery;
  };
  console.debug("getUsersFromDb got results: ", users);
  return users?.data?.listUserPublics?.items as UserPublic[];
}

export async function updateUserPublicInDb(
  values: UserUpdateValues
): Promise<UserPublic> {
  console.debug("updating user:", values);
  const result = (await API.graphql(
    graphqlOperation(updateUserPublic, {
      input: { ...values },
    })
  )) as { data: UpdateUserPublicMutation };
  console.debug("result:", result);
  return result?.data?.updateUserPublic as UserPublic;
}

export async function listUsersInDb(): Promise<Array<UserPublic>> {
  console.debug("listing users");
  let results: Array<UserPublic> = [];
  let nextToken: string | null | undefined = null;
  do {
    const result = (await API.graphql(
      graphqlOperation(listUserPublics, {
        input: {
          limit: 25,
          nextToken,
        },
      })
    )) as { data: ListUserPublicsQuery };
    const users = result?.data?.listUserPublics?.items as Array<UserPublic>;
    if (users) {
      results.push(...users);
    }
    nextToken = result?.data?.listUserPublics?.nextToken;
  } while (nextToken && nextToken !== null);
  return results;
}

/////////////// REST API //////////////////////
type NullableBoolean = boolean | null | undefined;

export class UserAdminInfo extends Entity {
  id = "";
  email = "";
  displayName = "";
  createdAtUtc = "";
  accountState = "";
  stripeCustomerId = "";
  notificationsAllow: NullableBoolean = undefined;
  settingSharingEmailsIncludeCalendarInvites: NullableBoolean = undefined;
  settingSharingEmailsSelfWhenNotSharing: NullableBoolean = undefined;
  pushIds: string[] = [];

  pk(
    parent?: any,
    key?: string | undefined,
    args?: readonly any[] | undefined
  ): string | undefined {
    return this.id;
  }
}

export const usersAdminInfoEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/users",
  schema: { users: [UserAdminInfo], nextToken: "" },
});

export const usersAdminInfoEndpointPaginated =
  usersAdminInfoEndpoint.paginated("nextToken");

export const sendPushEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/user/:id/send-push",
  method: "POST",
  schema: { messageIds: [] },
});

export const resendUserActivationEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/user/:id/resend-activation",
  method: "POST",
  sideEffect: true,
  schema: { id: "" },
});

export const createUserEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/user",
  method: "POST",
  sideEffect: true,
  schema: UserAdminInfo,
});

export const UserResource = {
  getList: usersAdminInfoEndpoint,
  sendPush: sendPushEndpoint,
  resendActivation: resendUserActivationEndpoint,
  createUser: createUserEndpoint,
};
