import React from "react";

import { ApolloClient, ApolloError, gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    deleteGoal,
    deleteGoalDay,
    DeleteGoalDayMutation,
    DeleteGoalDayMutationVariables,
    DeleteGoalMutation,
    DeleteGoalMutationVariables,
    Goal,
    GoalDay,
    listGoalDays,
    ListGoalDaysQuery, ListGoalDaysQueryVariables, UserPrivate
} from '@focusedspace/gql-api';
import { endOfDay, startOfDay, subHours } from "date-fns";
import { getGoalsForGoalDay } from "./Goals";
import { getUserPrivateByIds } from "./Users";

type ReturnStatusType = { loading: boolean; error: ApolloError | undefined };

export const deleteGoalById = async (client: ApolloClient<object>, id: string) => {
    const variables: DeleteGoalMutationVariables = {
        input: {
            id
        },
    };
    const res = await client.mutate<DeleteGoalMutation>({
        mutation: gql(deleteGoal),
        variables,
    });
    if (res.errors && res.errors.length > 0) {
        throw new Error(JSON.stringify(res.errors));
    }
    
    return res.data!.deleteGoal as Goal;
};

export type DeleteGoalDayResults = {
    success: boolean;
    goalsDeleted: number;
};

export const deleteGoalDayAndGoals = async (client: ApolloClient<object>, goalDayId: string) => {
    const goals = await getGoalsForGoalDay(client, goalDayId);
    const deletePromises = goals.map(goal => deleteGoalById(client, goal.id));
    const deletedGoals = await Promise.all(deletePromises);

    const variables: DeleteGoalDayMutationVariables = {
        input: {
            id: goalDayId
        },
    };
    const res = await client.mutate<DeleteGoalDayMutation>({
        mutation: gql(deleteGoalDay),
        variables,
    });

    if (res.errors && res.errors.length > 0) {
        throw new Error(JSON.stringify(res.errors));
    }

    const results: DeleteGoalDayResults = {
        success: true,
        goalsDeleted: deletedGoals.length
    };

    return results;
};

export type GoalDaysAndUserPrivateData = {
    goalDays: GoalDay[];
    userPrivateData: Map<string, UserPrivate>;
}

export const getGoalDaysForDates = async (client: ApolloClient<object>, start: Date, end: Date | undefined = undefined): Promise<GoalDaysAndUserPrivateData> => {
    const limit = 100;

    var endDate = end || start;

    var nextToken: string | null | undefined = null;
    const goalDays: GoalDay[] = [];

    do {
        const variables: ListGoalDaysQueryVariables = {
            limit: limit,
            nextToken: nextToken,
            filter: {
                dayStartUtc: {
                    between: [subHours(startOfDay(start), 2).toISOString(), endOfDay(endDate).toISOString()]
                }
            }
        };
        const res = await client.query<ListGoalDaysQuery>({
            query: gql(listGoalDays),
            variables: variables
        });
        if (res.error) {
            throw res.error;
        }
        if (res.data) {
            const data = res.data;
            if (data.listGoalDays) {
                const listGoalDays = data.listGoalDays;
                if (listGoalDays.items) {
                    listGoalDays.items.forEach(item => {
                        if (item) {
                            goalDays.push(item as GoalDay);
                        }
                    });
                }
                nextToken = listGoalDays.nextToken;
            }
        }
    } while (nextToken);

    if (goalDays.length === 0) {
        return {goalDays: [], userPrivateData: new Map()};
    }

    const userPrivateData = await getUserPrivateByIds(client, goalDays.map(goalDay => goalDay.userId));

    return {goalDays, userPrivateData};
}
