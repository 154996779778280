import { ApolloClient, gql } from "@apollo/client";
import { Goal, GoalDay, listGoals, ListGoalsQuery, ListGoalsQueryVariables } from "@focusedspace/gql-api";

export const getGoalsForGoalDay = async (client: ApolloClient<object>, goalDayId: string) => {
    const limit = 100;

    var nextToken: string | null | undefined = null;
    const goals: Goal[] = [];

    do {
        const variables: ListGoalsQueryVariables = {
            limit: limit,
            nextToken: nextToken,
            filter: {
                goalDayId: {
                    eq: goalDayId
                }
            }
        };
        const res = await client.query<ListGoalsQuery>({
            query: gql(listGoals),
            variables: variables
        });
        if (res.error) {
            throw res.error;
        }
        if (res.data) {
            const data = res.data;
            if (data.listGoals) {
                const listGoalsData = data.listGoals;
                if (listGoalsData.items) {
                    listGoalsData.items.forEach(item => {
                        if (item) {
                            goals.push(item as Goal);
                        }
                    });
                }
                nextToken = listGoalsData.nextToken;
            }
        }
    } while (nextToken);

    return goals;
}